(<template>
  <section class="video-service">
    <div v-for="(section, index) in sections"
         :key="index"
         class="video-service__section">
      <h3 class="video-service__title">{{ section.title }}</h3>
      <p v-if="section.mainText"
         class="video-service__main-text"
         v-html="section.mainText"></p>
      <p v-for="(step, stepIndex) in section.steps"
         :key="stepIndex"
         v-html="getStepText(step, stepIndex)"></p>
      <p v-if="section.text"
         class="video-service__text"
         v-html="section.text"></p>
    </div>
  </section>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    data() {
      return {
        domain: this.$getString('domainData', 'platformName')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter'
      ]),
      ...mapGetters('OneAssignmentStore', [
        'customerUseOwnVideoSystem'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: ({storeJobObj}) => storeJobObj.interpretationRequirement || {}
      }),
      jobId() { return this.$route.params.id; },
      videoPlatform() { return this.assignmentRequirements.videoPlatform || {}; },
      videoPlatformInstructions() { return this.assignmentRequirements.videoPlatformInstructions || ''; },
      meetingLink() { return `https://video.salita.no/meetings/?external_reference=${this.jobId}`; },
      sectionsList() {
        if (!this.customerUseOwnVideoSystem) {
          return [{
            order: this.customerUseOwnVideoSystem ? 2 : 1,
            condition: true,
            mainText: this.$gettext('In case you have problems with setting up chosen video platform, you can still use our video solution'),
            title: this.$gettextInterpolate(this.$gettext('Use %{domain}\'s secure video system'), {
              domain: this.domain
            }),
            steps: [
              this.$gettextInterpolate(this.$gettext('Click here to copy the video meeting link for this assignment: <a href="%{link}" class="sk-link sk-link--default video-service__link js-copy-to-clipboard">%{link}</a>.'), {
                link: this.meetingLink
              }),
              this.$gettext('You can send this link to external participants.'),
              this.$gettextInterpolate(this.$gettext('You can join the meeting by visiting the link or clicking this button below: <a href="%{link}" target="_blank" class="sk-btn sk-btn--default video-service__btn js-meeting-link">Start the video conference</a>'), {
                link: this.meetingLink
              }),
              this.$gettext('You can also add people to the meeting from the video room.'),
              this.$gettext('The video room is limited to 4 participants (computers/phones). Please contact us if you need a larger room.'),
              this.$gettext('After all participants have entered the video room, press "Lock" to lock the room. It will prevent unauthorized access. You can always reopen the room for more participants to join.')
            ],
            text: this.$gettext('The video service is provided by our partner <a class="sk-link sk-link--gray-bg video-service__link" href="https://whereby.com/" target="_blank">Whereby</a>')
          }, {
            order: this.customerUseOwnVideoSystem ? 1 : 2,
            condition: this.userIsNotInterpreter,
            title: this.$gettext('Use your video system'),
            steps: [
              this.$gettext('Once we have assigned an interpreter to your assignment, you will receive the e-mail address of the interpreter.'),
              this.$gettext('It is your responsibility to send the interpreter the details of how they will attend your video conference.'),
            ],
            text: this.$gettextInterpolate(this.$gettext('Note that if technical difficulties make it impossible to proceed with this assignment on an external video player, the assignment will still be invoiced. External technical issues on a platform not related to Salita are not in our control, and our recommendation is to use %{domain}\'s video system for both clients and interpreters.'), {
              domain: this.domain
            })
          }, {
            order: 1,
            condition: this.userIsInterpreter && this.customerUseOwnVideoSystem,
            title: this.$gettext('Use the video system of the customer\'s choice'),
            mainText: this.$gettextInterpolate(
              this.$gettext('The customer will be using %{videoPlatformName} for this assignment. <br> The video meeting specifications was either come in the confirmation email or was sent by the customer in a separate email.'), {
                videoPlatformName: this.videoPlatform.name || this.$gettext('custom video solution')
              }
            ),
            text: this.videoPlatformInstructions || ''
          }].sort((section1, section2) => {
            return section1.order - section2.order;
          });
        } else {
          return [{
            order: this.customerUseOwnVideoSystem ? 1 : 2,
            condition: this.userIsNotInterpreter,
            title: this.$gettext('Use your video system'),
            steps: [
              this.$gettext('Once we have assigned an interpreter to your assignment, you will receive the e-mail address of the interpreter.'),
              this.$gettext('It is your responsibility to send the interpreter the details of how they will attend your video conference.'),
            ],
            text: this.$gettextInterpolate(this.$gettext('Note that if technical difficulties make it impossible to proceed with this assignment on an external video player, the assignment will still be invoiced. External technical issues on a platform not related to Salita are not in our control, and our recommendation is to use %{domain}\'s video system for both clients and interpreters.'), {
              domain: this.domain
            })
          }, {
            order: 1,
            condition: this.userIsInterpreter && this.customerUseOwnVideoSystem,
            title: this.$gettext('Use the video system of the customer\'s choice'),
            mainText: this.$gettextInterpolate(
              this.$gettext('The customer will be using %{videoPlatformName} for this assignment. <br> The video meeting specifications was either come in the confirmation email or was sent by the customer in a separate email.'), {
                videoPlatformName: this.videoPlatform.name || this.$gettext('custom video solution')
              }
            ),
            text: this.videoPlatformInstructions || ''
          }].sort((section1, section2) => {
            return section1.order - section2.order;
          });
        }
      },
      sections() {
        return this.sectionsList.filter((section) => section.condition) || [];
      }
    },
    methods: {
      getStepText(text, index) { return `<b>${index + 1}.</b> ${text}`; }
    }
  };
</script>

<style>
  .video-service__btn {
    display: block;
    width: max-content;
    margin: 10px 0;
    padding: 0 10px 0 35px;
    background-image: url(~@assets/imgs/assignment_types/posting/video_selected.svg);
    background-position: 10px 50%;
    background-size: 15px;
    background-repeat: no-repeat;
  }

  .video-service__link {
    display: inline-block;
    font-weight: normal;
  }
</style>

<style scoped>
  .video-service {
    width: 100%;
  }

  .video-service__section {
    margin: 10px -30px 0;
    padding: 20px 30px;
    background-color: #fff;
  }

  .video-service__title {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .video-service__main-text {
    margin-bottom: 20px;
  }

  .video-service__text {
    margin-top: 20px;
    color: #757575;
  }

  @media (max-width: 767px) {
    .video-service__section {
      margin: 10px -15px 0;
      padding: 15px;
    }
  }
</style>
